import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../actions/asesores';
import {api2,API_UR2,api } from '../../actions/_request';
import swal from 'sweetalert2';
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  TabContent, TabPane, Nav, NavItem, NavLink, CardText,Label
} from "reactstrap";

import Foto from '../../components/foto/index.js';
import Modal from "./PaquetesModal";
 import classnames from 'classnames'; 
 import ReactTable from "react-table";
import "react-table/react-table.css";
import Select from 'react-select';
import moment from 'moment';
const filterCaseInsensitive = (filter, row) => {
            const id = filter.pivotId || filter.id;
            if (row[id] !== null){
                return (
                    row[id] !== undefined ?
                        String((row[id]+'').toLowerCase()).includes(filter.value.toLowerCase())
                        :
                        true
                );
            }
        };
class User extends Component {
  constructor(props){
    super(props);

    this.state = {
      modal:false,
      title:'Paquete',
      usuario:{
        id : null,
        nombre_esp:'',
        nombre_ing:'',
        duracion:30,
        anticipo_pago:0,
        anticipacion:1,
        estado:1,
        agrupar_reservaciones:0,
        cantidad_max:10,
        maxima_cliente:11,
        min_anticipacion:12,
        intervalo_reservacion : 30,

        dia_0:true,
        inicio_0:'00:00',
        final_0:'00:00',
        anticipo_pago_0:'0',


        dia_1:false,
        inicio_1:'00:00',
        final_1:'00:00',
        anticipo_pago_1:'0',

         dia_2:false,
        inicio_2:'00:00',
        final_2:'00:00',
        anticipo_pago_2:'0',

         dia_3:false,
        inicio_3:'00:00',
        final_3:'00:00',
        anticipo_pago_3:'0',

         dia_4:false,
        inicio_4:'00:00',
        final_4:'00:00',
        anticipo_pago_4:'0',
         dia_5:false,
        inicio_5:'00:00',
        final_5:'00:00',
        anticipo_pago_5:'0',
         dia_6:false,
        inicio_6:'00:00',
        final_6:'00:00',
        anticipo_pago_6:'0',

        campo_especial:false


      },
      paquetes:[],
      areas:[],
      porcentajes:[],
      estados:[{label:'Activado',value:1},{label:'Desactivado',value:0}],
      duracion:[{label:'30 min',value:30},{label:'60 min',value:60},{label:'90 min',value:90},{label:'120 min',value:120},{label:'150 min',value:150},{label:'180 min',value:180},{label:'210 min',value:210},{label:'240 min',value:240},{label:'270 min',value:270},{label:'300 min',value:300}],
      intervalos_reservaciones:[{label:'30 min',value:30},{label:'60 min',value:60},{label:'90 min',value:90},{label:'120 min',value:120},{label:'150 min',value:150},{label:'180 min',value:180},{label:'210 min',value:210},{label:'240 min',value:240}],
     
      action : 'save',
      submit:false,
      activeTab:"1",
     

    }
      this.handeInputChange = this.handeInputChange.bind(this);
     
      this.handleSubmit = this.handleSubmit.bind(this);
  }



  cambiar_estado_paquete=(id,estado,estado_texto,data)=>{
        

        // console.log('Factura------>',factura)
 let {usuario} = this.state;
      let self = this;
      swal.queue([{
        title: 'Favor de Confirmar ',
        text: ` Se Cambiara el Estado a ${estado_texto} al Paquete: ${data.nombre_esp} `,
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Confirmar!',
        cancelButtonText: 'Cancelar!',
        showLoaderOnConfirm: true,
        preConfirm: () => {
          return api().get(`/cambiar_estado_paquete/${id}/${estado}`)
        .then(function(response)
        {
          if(response.status === 200)
          {
               swal.insertQueueStep({
                        type: 'success',
                        title: 'Se Cambio el estado correctamente'
                      })
                  self.busqueda_paquetes(usuario.id);
           
          }
        }).catch(() => {
              swal.insertQueueStep({
                type: 'error',
                title: 'Solicitar ayuda a un Administrador',
              })
            })
        }
      }])


        
    }


toggle=(evt, index = null, title = "Info Paquete")=>
    {
        let {modal} = this.state;

        this.setState({
            modal       : !modal,
            paquete_id : index,
            title       : title,
            
        });
    }

    abrir_modal_nuevo=(evt)=>{
      /*validamos que el Area Este Creada */
      let {usuario} = this.state;
      if(usuario.id!=null){

          this.toggle();

      }else{
        swal.fire("Alerta!", `Primero tienes que Guardar el Area `, "warning");
      }
    }

    retorno_paquete=(paquete)=>{
      let {usuario} = this.state;
   this.busqueda_paquetes(usuario.id);
   this.toggle();


    }


  toggle_tab=(tab)=> {
        if (this.state.activeTab !== tab) {
          this.setState({
            activeTab: tab
          });
        }
      }


      busqueda_paquetes=(id)=>{
          let _self=this;
        api2().get("/getpaquetes/"+id)
              .then(function(response){
                if(response.status === 200){
                  let datos = response.data.datos;

                 
                  
                  _self.setState({
                    paquetes : datos,
                  
                    
                  })
                }

              });
      }

  componentDidMount(){
    

    let _self = this;
    let {usuario,porcentajes} = this.state;
    let {params} = this.props.match;

    for (var i = 0 ; i <=100; i++) {
          porcentajes.push({label:i,value:i})
          }
          _self.setState({
                   
                    porcentajes:porcentajes
                    
                  })

            if(params.id) {

              api2().get("/getarea/"+params.id)
              .then(function(response){
                if(response.status === 200){
                  let area = response.data.area;

                 _self.busqueda_paquetes(area.id);
                  
                  _self.setState({
                    usuario : area,
                  
                    
                  })
                }

              });
            }



      


  }
    handleSelectChange=(select, name)=> {
      
        
        const value = select === null ? null : select.value;
      
             this.setState({
                
                usuario: {
                    ...this.state.usuario,
                    [name]: value
                }
            }, () => {
         
        });
        
    }

    handeInputChange(event){
    let {usuario} = this.state;
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    usuario[name] = value;
   
    this.setState({
        usuario : usuario
    });
  }

    validar_hora=(activo,i,f)=>{
      let bandera= true;
      // console.log('activo--->',activo)
      if(activo){
        /*verificamos que las horas no sean iguales*/
        let inicio=moment.duration(i, "HH:mm"); //moment('2021-11-12 '+ i);
        let final= moment.duration(f, "HH:mm"); //moment('2021-11-12 '+ f);
        var diff = final.subtract(inicio);
        if(diff.hours()>0||diff.minutes()>0){

        }else{
          bandera=false;
        }

      }

      


      return bandera
    }

  handleSubmit(evt){

    evt.preventDefault();
    let _self = this;
    let {usuario} = this.state;
    // _self.setState({
    //         submit : true
    //       })

    const dias=['Lunes','Martes','Miercoles','Jueves','Viernes','Sabado','Domingo']


    let actual = moment(moment().format('MM/DD/YYYY'));
   

    let inicio=moment('2021-11-12 '+ usuario.hora_inicio);
    let final=moment('2021-11-12 '+ usuario.hora_final);

    /*validamos  los horarios*/


    // for (var i = 0; i <= 6; i++) {
    //   console.log('dia_'+i)
    //   let ad=this.validar_hora(usuario['dia_'+i],usuario['inicio_'+i],usuario['final_'+i])
    //   console.log('valiacion-->',ad)
    //   if(!ad){
    //        swal.fire("Alerta!", `el horario del dia ${dias[i]} es incorrecto`, "warning");
    //       return
    //   }
    // }


      api().post(`/created_updated_areas`,usuario)
        .then(function(response)
        {
           if(response.status){
                  if(response.data.estado){
                    swal.fire("Bien hecho!", "La Informacion fue Guardada con éxito!", "success");
                    // _self.props.history.push('/app/bloqueos');

                    usuario.id=response.data.id;
                     _self.setState({
                        submit : false,
                        usuario:usuario,
                      });
                  }else{


                    swal.fire("Alerta!", response.data.mensaje, "warning");
                    _self.setState({
                        submit : false,
                        usuario:usuario,
                      });
                  }
                }else{
                  // console.log('error')
                  swal.fire({
                            type: 'error',
                            title: 'Solicitar ayuda a un Administrador',
                          })
                  _self.setState({
                        submit : false
                      });
                }
        }).catch(() => {
          swal.fire("Alerta!", 'Solicitar ayuda a un Administrador', "warning");
         
            }) 
        
  }



  handeInputChange(event){
    let {usuario} = this.state;
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    usuario[name] = value;
   
    this.setState({
        usuario : usuario
    });
  }







 

  

    regresar=(evt)=>
    {
      evt.preventDefault();
      
      this.props.history.push('/app/areas');
    }
  render() {
     let {usuario,submit,experiencia,educacion} = this.state;
     // console.log('state---->',this.state)
    return (
      
        <div className="content">

        {
                   this.state.modal&& 
                   <Modal 
                 open={this.state.modal} 
                 toggle={this.toggle} 
                 title={this.state.title} 
                 paquete_id={this.state.paquete_id}
                 area_id={this.state.usuario.id}
                 retorno_paquete={this.retorno_paquete}
                />
                }
          <Row className='justify-content-center'>
          <Col  md="12">
          <Nav tabs>
            <NavItem>
              <NavLink
                className={classnames({ active: this.state.activeTab === '1' })}
                onClick={() => { this.toggle_tab('1'); }}
              >
                Datos Area
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({ active: this.state.activeTab === '2' })}
                onClick={() => { this.toggle_tab('2'); }}
              >
               Paquetes
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({ active: this.state.activeTab === '3' })}
                onClick={() => { this.toggle_tab('3'); }}
              >
               Campo Especial
              </NavLink>
            </NavItem>
          </Nav>
          </Col>
            <Col md="12">
            <TabContent activeTab={this.state.activeTab}>
              <TabPane tabId="1">
              <Card className="card-user">
                <CardBody>
                <Row>
                <Col> 
                  <Form onSubmit={this.handleSubmit}>
                    <Row className='pt-4'>
                       <Col md="12">
                         <Row>
                            <Col className="pr-1" md="6">
                              <FormGroup>
                                <label>Descripión Español</label>
                                <Input
                                  placeholder=""
                                  type="text"
                                  name="nombre_esp"
                                  required
                                  value={usuario.nombre_esp}
                                  onChange={this.handeInputChange}
                                />
                              </FormGroup>
                            </Col>
                          <Col className="pr-1" md="6">
                            <FormGroup>
                              <label>Descripión Ingles</label>
                              <Input
                                placeholder=""
                                type="text"
                                name="nombre_ing"
                                required
                                value={usuario.nombre_ing}
                                onChange={this.handeInputChange}
                              />
                            </FormGroup>
                          </Col>
                          <div className="col-md-2"></div> 
                          <div className="col-md-2"> 
                            <div className="form-group">
                                <label >Estado *:</label>
                                <Select
                                    placeholder=""
                                    type="text"
                                    name="duracion"
                                    options={this.state.estados}
                                     value={this.state.estados.filter(option => option.value == usuario.estado)}
                                    onChange={ (select) => { this.handleSelectChange(select, 'estado') } }
                                    clearable={false}

                                />
                            </div>
                          </div>
                          <div className="col-md-2"> 
                            <div className="form-group">
                              <label >Duracion *:</label>
                              <Select
                                  placeholder=""
                                  type="text"
                                  name="duracion"
                                  options={this.state.duracion}
                                   value={this.state.duracion.filter(option => option.value == usuario.duracion)}
                                  onChange={ (select) => { this.handleSelectChange(select, 'duracion') } }
                                  clearable={false}

                              />
                            </div>
                          </div>
                          <div className="col-md-2"> 
                              <div className="form-group">
                                  <label >Dias Anticipo Max*:</label>
                                  <Select
                                      placeholder=""
                                      type="text"
                                      name="anticipacion"
                                      options={this.state.porcentajes}
                                       value={this.state.porcentajes.filter(option => option.value == usuario.anticipacion)}
                                      onChange={ (select) => { this.handleSelectChange(select, 'anticipacion') } }
                                      clearable={false}

                                  />
                              </div>
                          </div>
                          <div className="col-md-2"> 
                              <div className="form-group">
                                  <label >Dias Anticipo Min *:</label>
                                  <Select
                                      placeholder=""
                                      type="text"
                                      name="min_anticipacion"
                                      options={this.state.porcentajes}
                                       value={this.state.porcentajes.filter(option => option.value == usuario.min_anticipacion)}
                                      onChange={ (select) => { this.handleSelectChange(select, 'min_anticipacion') } }
                                      clearable={false}

                                  />
                              </div>
                          </div>
                          <div className="col-md-2"></div> 
                          <div className="col-md-2"></div> 
                          <div className="col-md-2 d-none"> 
                              <div className="form-group pt-2">
                                <br/>
                                <label >Anticipo *:</label>
                                <Select
                                  placeholder=""
                                  type="text"
                                  name="anticipo_pago"
                                  options={this.state.porcentajes}
                                   value={this.state.porcentajes.filter(option => option.value == usuario.anticipo_pago)}
                                  onChange={ (select) => { this.handleSelectChange(select, 'anticipo_pago') } }
                                  clearable={false}
                                />
                              </div>
                          </div>
                          <Col md="2">
                            <FormGroup>
                              <Label className='label_checkbox' >
                                Activar{' '}
                                <Input className='checkbox' type="checkbox" 
                                  name='agrupar_reservaciones'
                                  checked={usuario.agrupar_reservaciones }
                                  onChange={this.handeInputChange}/>
                              </Label>
                              <br/>
                              <Label className='label_checkbox' >
                                Reservaciones Max.
                              </Label>
                              <Input
                                placeholder=""
                                type="number"
                                name="cantidad_max"
                                value={usuario.cantidad_max}
                                onChange={this.handeInputChange}
                                clearable={false}
                                required
                              />
                            </FormGroup>
                          </Col>
                          <Col className="pr-1" md="2">
                            <FormGroup>
                            <br/>
                              <label>Cantidad Max Cliente</label>
                              <Input
                                placeholder=""
                                type="number"
                                name="maxima_cliente"
                                required
                                value={usuario.maxima_cliente}
                                onChange={this.handeInputChange}
                              />
                            </FormGroup>
                          </Col>
                       
                          <div className="col-md-2 pr-1"> 
                            <div className="form-group">
                            <br/>
                              <label >Intervalo Reservaciones *:</label>
                              <Select
                                  placeholder=""
                                  type="text"
                                  name="intervalo_reservacion"
                                  options={this.state.intervalos_reservaciones}
                                  value={this.state.intervalos_reservaciones.filter(option => option.value == usuario.intervalo_reservacion)}
                                  onChange={ (select) => { this.handleSelectChange(select, 'intervalo_reservacion') } }
                                  clearable={false}

                              />
                            </div>
                          </div>
                        </Row>
                      </Col>

                      <Col md="12">
                        <Row className='justify-content-center'>
                          <Col md="2">
                            <Label className='label_checkbox' >
                              Lunes{' '}
                              <Input 
                                className='checkbox' 
                                type="checkbox" 
                                name='dia_0'
                                checked={usuario.dia_0 }
                                onChange={this.handeInputChange}
                              />
                            </Label>
                            <br/>
                            Inicio
                            <Input
                              placeholder=""
                              type="time"
                              name="inicio_0"
                              value={usuario.inicio_0}
                              onChange={this.handeInputChange}
                              clearable={false}
                            />
                             Final
                            <Input
                              placeholder=""
                              type="time"
                              name="final_0"
                              value={usuario.final_0}
                              onChange={this.handeInputChange}
                              clearable={false}
                            />
                              
                                Anticipo *
                                <Select
                                  placeholder=""
                                  type="text"
                                  name="anticipo_pago_0"
                                  options={this.state.porcentajes}
                                   value={this.state.porcentajes.filter(option => option.value == usuario.anticipo_pago_0)}
                                  onChange={ (select) => { this.handleSelectChange(select, 'anticipo_pago_0') } }
                                  clearable={false}
                                />
                       
                          </Col>
                      <Col md="2">
                        <Label className='label_checkbox' >
                          Martes{' '}
                          <Input 
                            className='checkbox' 
                            type="checkbox" 
                            checked={usuario.dia_1 }
                            name='dia_1'
                            onChange={this.handeInputChange}/>
                        </Label>
                        <br/>
                        Inicio
                        <Input
                          placeholder=""
                          type="time"
                          name="inicio_1" 
                          value={usuario.inicio_1}
                          onChange={this.handeInputChange}
                          clearable={false}
                        />
                        Final
                        <Input
                          placeholder=""
                          type="time"
                          name="final_1"
                          value={usuario.final_1}
                          onChange={this.handeInputChange}
                          clearable={false}
                        />
                         Anticipo *
                                <Select
                                  placeholder=""
                                  type="text"
                                  name="anticipo_pago_1"
                                  options={this.state.porcentajes}
                                   value={this.state.porcentajes.filter(option => option.value == usuario.anticipo_pago_1)}
                                  onChange={ (select) => { this.handleSelectChange(select, 'anticipo_pago_1') } }
                                  clearable={false}
                                />
                      </Col>
                      <Col md="2">
                      
                        <Label className='label_checkbox' >
                          Miercoles{' '}
                          <Input 
                            className='checkbox' 
                            type="checkbox" 
                            checked={usuario.dia_2 }
                            name='dia_2'
                            onChange={this.handeInputChange}
                          />
                        </Label>
                        <br/>
                        Inicio
                        <Input
                          placeholder=""
                          type="time"
                          name="inicio_2"
                          value={usuario.inicio_2}
                          onChange={this.handeInputChange}
                          clearable={false}
                        />
                        Final
                        <Input
                          placeholder=""
                          type="time"
                          name="final_2"
                          value={usuario.final_2}
                          onChange={this.handeInputChange}
                          clearable={false}
                        />
                         Anticipo *
                                <Select
                                  placeholder=""
                                  type="text"
                                  name="anticipo_pago_2"
                                  options={this.state.porcentajes}
                                   value={this.state.porcentajes.filter(option => option.value == usuario.anticipo_pago_2)}
                                  onChange={ (select) => { this.handleSelectChange(select, 'anticipo_pago_2') } }
                                  clearable={false}
                                />
                      </Col>
                      <Col md="2">
                      
                        <Label className='label_checkbox' >
                          Jueves{' '}
                          <Input 
                            className='checkbox' 
                            type="checkbox" 
                            checked={usuario.dia_3 }
                            name='dia_3'
                            onChange={this.handeInputChange}
                          />
                        </Label>
                        <br/>
                        Inicio
                        <Input
                          placeholder=""
                          type="time"
                          name="inicio_3"
                          value={usuario.inicio_3}
                          onChange={this.handeInputChange}
                          clearable={false}
                        />
                        Final
                        <Input
                          placeholder=""
                          type="time"
                          name="final_3"
                          value={usuario.final_3}
                          onChange={this.handeInputChange}
                          clearable={false}
                        />
                         Anticipo *
                                <Select
                                  placeholder=""
                                  type="text"
                                  name="anticipo_pago_3"
                                  options={this.state.porcentajes}
                                   value={this.state.porcentajes.filter(option => option.value == usuario.anticipo_pago_3)}
                                  onChange={ (select) => { this.handleSelectChange(select, 'anticipo_pago_3') } }
                                  clearable={false}
                                />
                      </Col>
                    </Row>
                  </Col>
                  <Col md="12">
                    <Row className='justify-content-center mt-2'>
                      <Col md="2">
                        <Label className='label_checkbox' >
                        Viernes{' '}
                        <Input className='checkbox' type="checkbox" 
                        checked={usuario.dia_4 }
                        name='dia_4'
                        onChange={this.handeInputChange}/>
                        </Label>
                        <br/>
                        Inicio
                        <Input
                          placeholder=""
                          type="time"
                          name="inicio_4"
                          value={usuario.inicio_4}
                          onChange={this.handeInputChange}
                          clearable={false}
                        />
                        Final
                        <Input
                          placeholder=""
                          type="time"
                          name="final_4"
                          value={usuario.final_4}
                          onChange={this.handeInputChange}
                          clearable={false}
                        />
                         Anticipo *
                                <Select
                                  placeholder=""
                                  type="text"
                                  name="anticipo_pago_4"
                                  options={this.state.porcentajes}
                                   value={this.state.porcentajes.filter(option => option.value == usuario.anticipo_pago_4)}
                                  onChange={ (select) => { this.handleSelectChange(select, 'anticipo_pago_4') } }
                                  clearable={false}
                                />
                      </Col>
                      <Col md="2">
                        <Label className='label_checkbox' >
                        Sabado{' '}
                        <Input 
                          className='checkbox' 
                          type="checkbox" 
                          checked={usuario.dia_5 }
                          name='dia_5'
                          onChange={this.handeInputChange}/>
                        </Label>
                        <br/>
                        Inicio
                        <Input
                          placeholder=""
                          type="time"
                          name="inicio_5"
                          value={usuario.inicio_5}
                          onChange={this.handeInputChange}
                          clearable={false}
                        />
                        Final
                        <Input
                          placeholder=""
                          type="time"
                          name="final_5"
                          value={usuario.final_5}
                          onChange={this.handeInputChange}
                          clearable={false}
                        />
                         Anticipo *
                                <Select
                                  placeholder=""
                                  type="text"
                                  name="anticipo_pago_5"
                                  options={this.state.porcentajes}
                                   value={this.state.porcentajes.filter(option => option.value == usuario.anticipo_pago_5)}
                                  onChange={ (select) => { this.handleSelectChange(select, 'anticipo_pago_5') } }
                                  clearable={false}
                                />
                      </Col>
                      <Col md="2">
                      
                        <Label className='label_checkbox' >
                          Domingo{' '}
                          <Input 
                            className='checkbox' 
                            type="checkbox" 
                            checked={usuario.dia_6 }
                            name='dia_6'
                            onChange={this.handeInputChange}
                          />
                        </Label>
                        <br/>
                        Inicio
                        <Input
                          placeholder=""
                          type="time"
                          name="inicio_6"
                          value={usuario.inicio_6}
                          onChange={this.handeInputChange}
                          clearable={false}
                        />
                        Final
                        <Input
                          placeholder=""
                          type="time"
                          name="final_6"
                          value={usuario.final_6}
                          onChange={this.handeInputChange}
                          clearable={false}
                        />
                         Anticipo *
                                <Select
                                  placeholder=""
                                  type="text"
                                  name="anticipo_pago_6"
                                  options={this.state.porcentajes}
                                   value={this.state.porcentajes.filter(option => option.value == usuario.anticipo_pago_6)}
                                  onChange={ (select) => { this.handleSelectChange(select, 'anticipo_pago_6') } }
                                  clearable={false}
                                />
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row className='mt-2'>
                  <Col md="6">
                    <FormGroup>
                      <Label className='label_checkbox' >
                        Activar Campo Especial{' '}
                        <Input className='checkbox' type="checkbox" 
                          name='campo_especial'
                          checked={usuario.campo_especial }
                          onChange={this.handeInputChange}/>
                      </Label>
                      <br/>
                      <Label className='label_checkbox' >
                        Texto Superior Especial esp
                      </Label>
                      <Input
                        placeholder=""
                        type="text"
                        name="texto_especial_esp"
                        value={usuario.texto_especial_esp}
                        onChange={this.handeInputChange}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup className="pt-1">
                      <br/>
                      <Label className='label_checkbox' >
                        Texto Superior Especial Ing
                      </Label>
                      <Input
                        placeholder=""
                        type="text"
                        name="texto_especial_ing"
                        value={usuario.texto_especial_ing}
                        onChange={this.handeInputChange}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <Label className='label_checkbox' >
                        Label Campo Especial esp
                      </Label>
                      <Input
                        placeholder=""
                        type="text"
                        name="label_especial_esp"
                        value={usuario.label_especial_esp}
                        onChange={this.handeInputChange}
                        required={usuario.campo_especial}
                        maxLength={50}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <Label className='label_checkbox' >
                        Label Campo Especial Ing
                      </Label>
                      <Input
                        placeholder=""
                        type="text"
                        name="label_especial_ing"
                        value={usuario.label_especial_ing}
                        onChange={this.handeInputChange}
                        required={usuario.campo_especial}
                        maxLength={50}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <Label className='label_checkbox' >
                        Texto Inferior Especial Esp
                      </Label>
                      <Input
                        placeholder=""
                        type="text"
                        name="texto2_especial_esp"
                        value={usuario.texto2_especial_esp}
                        onChange={this.handeInputChange}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <Label className='label_checkbox' >
                        Texto Inferior Especial Ing
                      </Label>
                      <Input
                        placeholder=""
                        type="text"
                        name="texto2_especial_ing"
                        value={usuario.texto2_especial_ing}
                        onChange={this.handeInputChange}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                  
                <Row>
                <br/>
                  <div className="update ml-auto mr-auto">
                  <br/>
                    <Button
                      className="btn-round"
                      color="primary"
                      type="submit"
                      disabled={submit}
                    >
                      Guardar
                    </Button>
                    <Button
                      className="btn-round"
                      color="warning"
                      onClick={this.regresar}>
                      Regresar
                    </Button>
                  </div>
                </Row>
            </Form>       
          </Col>         
        </Row>        
      </CardBody>
    </Card>
  </TabPane>     
  <TabPane tabId="2">
    <Card className="card-user">
     
      <CardBody>
      <Button
                className="btn-round"
                color="warning"
                
                onClick={(evt) => { this.abrir_modal_nuevo(evt); }}>
                Nuevo
              </Button>
      <ReactTable                             
                    
                    className = "-striped -highlight"
                    columns   = {[
                      {Header: 'Estado', accessor: 'estado',minWidth:150,style: { textAlign: 'center'}, 
                      Cell: (row) =>
                          {
                              return(
                                  <div>{row.original.estado==1?'Activo':'Deshabilitado'}</div>
                              )
                          },
                           Filter: ({ filter, onChange }) =>

                           <select name="estado" id="estado" onChange={event => onChange(event.target.value)}
                                   value={filter ? filter.value : ""}>
                            <option value="">Todos</option>
                            <option value="1">Activo</option>
                            <option value="0">Deshabilitado</option>
                          
                          </select>
                       
                        } ,
                        {Header: 'Tipo', accessor: 'tipo',minWidth:150,style: { textAlign: 'center'}, 
                      Cell: (row) =>
                          {
                              return(
                                  <div>{row.original.tipo==1?'Personas':'Mesas'}</div>
                              )
                          },
                           Filter: ({ filter, onChange }) =>

                           <select name="estado" id="estado" onChange={event => onChange(event.target.value)}
                                   value={filter ? filter.value : ""}>
                            <option value="">Todos</option>
                            <option value="1">Personas</option>
                            <option value="2">Mesas</option>
                          
                          </select>
                       
                        } ,
                      {Header: 'Paquete', accessor: 'nombre_esp'},
                      {Header: 'Cantidad', accessor: 'cantidad',filterable: false,sortable: false,},
                      {Header: 'Precio', accessor: 'precio',filterable: false,sortable: false,},
                     
                      {
                          Header: 'Controles',
                          filterable: false,
                          sortable: false,
                           // maxWidth:180,
                          Cell: (row) =>
                          {
                              return(
                                  <div className="text-center">
                                      <Button
                                       color="success" 
                                       className="btn-sm" onClick={(evt)=>{this.toggle(evt,row.original.id); }}>
                                          Editar
                                      </Button>
                                     { row.original.estado==1?
                                       <Button
                                       color="danger" 
                                       className="btn-sm" onClick={(evt)=>{this.cambiar_estado_paquete(row.original.id,0,'Deshabilitada',row.original); }}>
                                          Deshabilitar
                                      </Button>
                                      :
                                      <Button
                                       color="primary" 
                                       className="btn-sm" onClick={(evt)=>{this.cambiar_estado_paquete(row.original.id,1,'Activvar',row.original); }}>
                                          Activar
                                      </Button>
                                      }
                                    
                                  </div>
                              )
                          }
                      }
                    ]}
                  
                    data      = {this.state.paquetes}

                    filterable
                    defaultPageSize={15} 
                    
                    
                   

                  />

     </CardBody>
    </Card>
  </TabPane>
                  
  </TabContent>
            </Col>
          </Row>
        </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
    return {
        auth : state.auth,
    }
};

export default connect(mapStateToProps, actions)(User)

